import axios from "axios";
import MD5 from "crypto-js/md5";
import dayjs from "dayjs";

class QueryHelper {
	environment;
	// generics
	base_url;
	notification_url;
	base_config;

	constructor({ environment }) {
		this.environment = environment;

		if (this.environment === "production") {
			this.base_url = "https://northstar-recenterhub-core-prod-app.azurewebsites.net/api/app/";
			this.notification_url = "https://northstar-recenterhub-notification-prod-app.azurewebsites.net/api/app/";
		} else if (this.environment === "staging") {
			this.base_url = "https://northstar-recenterhub-core-dev-appsvc.azurewebsites.net/api/app/";
			this.notification_url = "https://northstar-notification-dev-appsvc.azurewebsites.net/api/app/";
		} else if (this.environment === "testing") {
			this.base_url = "https://northstar-recenterhub-core-dev-appsvc.azurewebsites.net/api/app/";
			this.notification_url = "https://northstar-notification-dev-appsvc.azurewebsites.net/api/app/";
		} else {
			this.base_url = "https://northstar-recenterhub-core-dev-appsvc.azurewebsites.net/api/app/";
			this.notification_url = "https://northstar-notification-dev-appsvc.azurewebsites.net/api/app/";
		}
		this.base_config = {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		};
	}

	operators = {
		Equal: 0,
		GreaterThan: 1,
		GreaterThanOrEqual: 2,
		LessThan: 3,
		LessThanOrEqual: 4,
		Between: 5,
		NotEqual: 6,
		NotEmpty: 7,
		Like: 8,
		HasAny: 9,
		Contains: 10,
		StartsWith: 11,
		NotLike: 12,
		ContainsAny: 13,
	};
	pageId = 0;
	pageSize = 50;
	search = [];
	field = "";
	criteria = [];
	orderBy = [];
	fields = [];
	use_q = "/q";

	get payload() {
		return {
			pageId: this.pageId,
			pageSize: this.pageSize,
			criteria: this.criteria,
			orderBy: this.orderBy,
			fields: this.fields,
		};
	}

	setMace() {
		return;
	}

	setNotification() {
		this.base_url = this.notification_url;
	}

	setMembership() {
		return;
	}

	setMembershipV3() {
		return;
	}

	setMemberPortal() {
		return;
	}

	set payload(payload) {}

	async get(path) {
		const resp = await axios.get(this.base_url + path, this.base_config);
		return resp.data;
	}

	async put(path, payload) {
		const resp = await axios.put(this.base_url + path, payload, this.base_config);
		return resp.data;
	}

	async post(path, payload) {
		const resp = await axios.post(this.base_url + path, payload, this.base_config);
		return resp.data;
	}

	async fetchData(resource) {
		const resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);
		return resp.data;
	}

	async fetchDataWithCriteria(resource, criteria) {
		this.criteria = criteria;
		const resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);

		return resp.data;
	}

	async fetchAllData(resource, criteria = []) {
		this.pageSize = 10000;
		let totalPages = 1;
		let results = [];

		if (criteria && criteria.length) {
			this.criteria = criteria;
		}
		let resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);
		results = resp.data.results;

		totalPages = resp.data.totalResults / resp.data.pageSize;

		while (this.pageId + 1 < totalPages) {
			this.pageId++;
			resp = await axios.post(this.base_url + resource + this.use_q, this.payload, this.base_config);
			results = results.concat(resp.data.results);
		}

		return results;
	}

	async fetchLookupData(resource) {
		let results = [];

		let resp = await axios.get(`${this.base_url}lookupValues/${resource}`, this.base_config);
		results = resp.data.results;

		return results;
	}

	async fetchResource(resource, id, expanded) {
		let exp = expanded ? "/expanded" : "";
		const resp = await axios.get(this.base_url + resource + "/" + id + exp, this.base_config);
		return resp.data;
	}

	async createResource(resource, payload) {
		const resp = await axios.post(this.base_url + resource, payload, this.base_config);
		return resp.data;
	}

	async updateResource(resource, id, payload) {
		const resp = await axios.put(this.base_url + resource + "/" + id, payload, this.base_config);
		return resp.data;
	}

	async deleteResource(resource, id) {
		const resp = await axios.delete(this.base_url + resource + "/" + id, this.base_config);
		return resp.data;
	}

	async fetchAudienceMembers() {
		const resp = await axios.post(this.base_url + "Members/q", this.payload, this.base_config);
		return resp.data;
	}

	async fetchAudienceTypeAhead(searchString) {
		const resp = await axios.get(this.base_url + "Members/ta/" + searchString, this.base_config);
		return resp.data;
	}

	async fetchReportData(criteria) {
		const key = MD5(JSON.stringify(criteria)).toString();
		let existing = false;
		try {
			existing = JSON.parse(sessionStorage.getItem(key));
		} catch (e) {
			console.log(e);
		}

		if (existing && existing.hasOwnProperty("timestamp")) {
			if (dayjs.unix(existing.timestamp) > dayjs().add(50, "second").unix()) {
				try {
					console.log("returning cached");
					return new Promise(function (resolve, reject) {
						setTimeout(() => resolve(existing.data), 1);
					});
				} catch (e) {
					console.log(e);
				}
			} else {
				console.log("Cache expired");
			}
		} else {
			console.log("No cache, returning fresh");
			const resp = await axios.post(this.base_url + "WebhookEvent/Stats", criteria, this.base_config);
			let cached_data = JSON.stringify({
				timestamp: dayjs().unix(),
				data: resp.data,
			});

			sessionStorage.setItem(key, cached_data);
			return resp.data;
		}
	}

	async fetchAudienceMember(contact_id) {
		let payload = {
			pageId: 0,
			pageSize: 1,
			criteria: [
				{
					field: "id",
					op: 0,
					values: [contact_id],
				},
			],
		};
		const resp = await axios.post(this.base_url + "Members/q", payload, this.base_config);
		return resp.data;
	}

	async fetchAllAudienceMembers() {
		// set limit to max
		this.pageSize = 10000;
		let totalPages = 1;
		let results = [];
		let resp = await axios.post(this.base_url + "Members/q", this.payload, this.base_config);
		results = resp.data.results;

		totalPages = resp.data.totalResults / resp.data.pageSize;

		while (this.pageId + 1 < totalPages) {
			this.pageId++;
			resp = await axios.post(this.base_url + "Members/q", this.payload, this.base_config);
			results = results.concat(resp.data.results);
		}

		return results;
	}

	async sendTestEmail(criteria) {
		const resp = await axios.post(this.base_url + "Notification/SendPreview", criteria, this.base_config);
		return resp.data;
	}

	async csvUpload(audienceId, file) {
		const resp = await axios.post(this.base_url + "CustomContacts/uploadCsvFile/" + audienceId, file, this.base_config);
		return resp.data;
	}

	async getRenderedPdf(campaignId) {
		return await axios.get(`${this.base_url}Campaign/PDFRender/${campaignId}`, this.base_config);
	}
}

export default QueryHelper;
